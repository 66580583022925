/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ArgonDropzone from "components/ArgonDropzone";
import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function UploadLocatorDialog({ open, onClose, page }) {

  const dropzoneOptions = {
    addRemoveLinks: true,
    acceptedFiles: ".json",
    url: "/be/locator/uploadLocator",
    maxFiles: 1,
    multiple: false,
    autoProcessQueue: true,
    method: "POST",
    withCredentials: true,
    headers: {
      "Authorization": "Bearer " + (localStorage.getItem("jwt-token") || sessionStorage.getItem("jwt-token"))
    }
    ,

    init: function () {
      this.on("sending", function(file, xhr, formData){
        formData.append("page_title", page.page_title);
        formData.append("project", page.project);
        formData.append("capability", page.capability);
        formData.append("market", page.market);
        formData.append("env", page.env);
      });
      this.on("complete", (file) => {
        onClose();
        if (file.status === 'error') {
          enqueueSnackbar('There was an error uploading the json.', { variant: 'error' });
          console.log(file);
        } else if (file.status === 'canceled') {
          enqueueSnackbar('Json upload canceled', { variant: 'warning' });
          console.log(file);
        } else {
          enqueueSnackbar('Locator uploaded', { variant: 'success' });
        }
      });

      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "100%" } }
      }
      fullWidth={"md"}
      maxWidth={"md"}
    >
      <DialogTitle textAlign="center">{"Upload Locator (.json)"}</DialogTitle>
      <DialogContent>
        <div className="dropmehere" id="dropmehere">
          <ArgonDropzone options={dropzoneOptions} />
        </div>
      </DialogContent>
    </Dialog >
  );
}

function UploadLocator({ user, page }) {

  const [uploadLocatorOpen, setUploadLocatorOpen] = useState(false);

  const onCloseBinUpload = (reason) => {
    setUploadLocatorOpen(false);
  };

  return (
    <div id="upload-locator">
      <UploadLocatorDialog open={uploadLocatorOpen} onClose={onCloseBinUpload} page={page}/>
      <ArgonButton
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          startIcon={<FileUploadIcon />}
          onClick={() => setUploadLocatorOpen(true)}
      >
        Import Data
      </ArgonButton>
    </div>
  );
}

export default UploadLocator;
